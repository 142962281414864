import AllProductsPage from './allProductsPage';

const StorePage = (props) => {
  return (
    <div>
      <AllProductsPage />
    </div>
  );
}

export default StorePage;
